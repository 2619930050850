<template>
  <div class="row justify-content-center">
    <div class="col-lg-2 col-2">
      <a
        href="mailto:info@ki-hydrogen.com"
        aria-label="Email Us"
      ><font-awesome-icon
        class="fa-2xl"
        icon="fa-solid fa-envelope"
      /></a>
    </div>
    <div class="col-lg-2 col-2">
      <a
        href="https://www.linkedin.com/company/ki-hydrogen"
        aria-label="Follow us on LinkedIn"
        target="_blank"
      ><font-awesome-icon
        class="fa-2xl"
        icon="fa-brands fa-linkedin"
      /></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialIcons',
};

</script>
